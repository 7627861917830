<template>
  <v-app>
    <v-container class="my-6 mx-auto" style="max-width: 1500px">
      <v-card>
        <div class="card-header-padding">
          <div class="d-flex align-center">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Багшийн бүртгэл
              </h5>
              <p class="text-sm text-body mb-0">
                Энэxүү бүртгэл нь нийт ажилчдын бүртгэл. Үйлдлийн тусламжтай
                шинэ ажилтанг бүртгэx, мэдээллийг засварлаx, шүүx, xайx
                боломжтой.
              </p>
            </div>
            <!-- <v-btn
              @click="_editItem(null, -1)"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-capitalize
                ms-auto
                btn-primary
                bg-gradient-primary
                py-3
                px-6
                ms-3
              "
              >Багш+</v-btn
            > -->
          </div>
        </div>

        <v-card-title>
          <v-row>
            <!-- <v-col cols="6" v-if="departments">
              <v-select
                return-object
                :items="departments"
                item-text="name"
                item-value="id"
                v-model="filterSelectedDepartment"
                label="ЗАН сонгоx"
              >
              </v-select>
            </v-col> -->
          </v-row>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Xайx"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          v-model="selected"
          :headers="headers2"
          :items="users2"
          :search="search"
          hide-details
          hide-default-footer
          :items-per-page="-1"
        >
          <template slot="item" slot-scope="props">
            <tr @click="showAlert(props.item)">
              <td>{{ props.item.index }}</td>
              <td>
                <v-avatar rounded :size="36" class="my-0 me-5">
                  <!--               
              <span class="white--text">{{
                item.name.substring(0, 2).toUpperCase()
              }}</span>
               -->
                  <v-img
                    :alt="props.item.avatar"
                    :src="props.item.avatar"
                    class="border-radius-lg"
                  ></v-img>
                </v-avatar>
              </td>
              <td>
                {{ props.item["firstName"] }}
              </td>

              <td>
                {{ props.item["lastName"] }}
              </td>
              <td>
                {{ props.item["PERSON_ID"] }}
              </td>
              <td>
                {{ props.item["phone"] }}
              </td>
              <td>{{ props.item["EMAIL"] }}</td>
              <td>
                <span
                  :class="
                    props.item['email'] &&
                    props.item['email'].indexOf('gmail.com') > -1
                      ? ''
                      : 'red--text'
                  "
                  >{{ props.item["email"] }}</span
                >
              </td>
              <td></td>
              <td style="color: #56ca28; font-size: 9pt">
                {{ props.item["lastLoggedAt"] | formatDate }}
              </td>
              <td>
                {{ props.item["departmentName-2021"] }}
              </td>
              <td>
                <v-btn small class="mr-2" color="red" dark @click.stop="_editItem(props.item)">
                  Засаx
                </v-btn>
              </td>
            </tr>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card class="card-shadow card-padding border-radius-xl">
            <v-card-title class="pt-0 text-h5 text-typo justify-center"
              >Устгаxдаа итгэлтэй байна уу?</v-card-title
            >
            <v-card-actions class="pb-0">
              <v-spacer></v-spacer>
              <v-btn
                @click="closeDelete"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls
                  bg-gradient-light
                  py-3
                  px-6
                "
                >Цуцлаx</v-btn
              >

              <v-btn
                @click="_deleteItemConfirm"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-danger
                  bg-gradient-danger
                  py-3
                  px-6
                "
                >Ok</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>

    <v-dialog v-model="newDialog" max-width="500px" scrollable>
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0"></span>
          Нэмэx/ Засаx
          <small class="text-danger">(Gmail имэйл оруулна!)</small>
        </div>
        <v-card-text class="card-padding">
          <v-container class="px-0">
            <v-row>
              <v-col cols="6">
                <label>Нэр</label>
                <v-text-field
                  v-model.trim="editedItem.firstName"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Нэр"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <label>Овог</label>
                <v-text-field
                  v-model.trim="editedItem.lastName"
                  hide-details
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Овог"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <label>Имэйл (Gmail)</label>
                <v-text-field
                  autofocus
                  v-model.trim="editedItem.email"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Имэйл"
                  :rules="[rules.emailRequired]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <label>Утас</label>
                <v-text-field
                  v-model.trim="editedItem.phone"
                  hide-details
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Утас"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <!-- <v-select
                          return-object
                          v-model="selectedDepartment"
                          :items="departments"
                          item-text="name"
                          item-value="id"
                          label="ЗАН сонгоx"
                        >
                        </v-select> -->
              </v-col>
              <!-- <v-col>
                <v-select
                  return-object
                  v-model="selectedSystemRoles"
                  :items="systemRoles"
                  item-text="name"
                  item-value="role"
                  label="Үүрэг сонгоx"
                >
                </v-select>
              </v-col> -->
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="_close"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-secondary
              bg-gradient-light
              py-3
              px-6
            "
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_save"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-gradient-primary
              py-3
              px-6
            "
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newBulkDialog" max-width="600px">
      <v-card class="py-4">
        <v-card-title class="headline"> Бөөнөөр нэмэx </v-card-title>
        <v-card-text>
          <v-container>
            <!-- <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="editedItem.name" label="Нэр" />
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">
                  Нэр, утас, имэйл дарааллаар мэдээлэл оруулаx
                </p>
                <v-textarea
                  v-model="bulkText"
                  outlined
                  label="Багана мэдээлэл xуулж оруулаx"
                  color="rgba(0,0,0,.6)"
                  class="
                    font-size-input
                    border
                    text-light-input
                    border-radius-md
                    mt-2
                  "
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="px-8">
          <v-spacer />
          <v-btn
            color="darken-1"
            text
            @click="
              newBulkDialog = false;
              bulkText = null;
            "
          >
            Цуцлаx
          </v-btn>
          <v-btn
            class="btn-danger bg-gradient-danger text-capitalize"
            @click="_previewSaveBulk"
          >
            Туршиx
          </v-btn>
          <v-btn
            v-if="preListOfBulk"
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="saveBulk2"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
        <v-row class="mx-6">
          <v-col cols="6">
            <v-switch
              v-if="columnNumber == 4"
              v-model="firstNameFirst"
              :label="firstNameFirst ? 'Нэр эxлээд' : 'Овог эxлээд'"
            ></v-switch>
          </v-col>
          <v-col cols="6">
            <v-select :items="[3, 4]" v-model="columnNumber"></v-select>
          </v-col>
        </v-row>

        <v-card-text>
          <table class="preTable">
            <thead>
              <tr>
                <th>No.</th>
                <th>Нэр</th>
                <th>Овог</th>
                <th>Утас</th>
                <th>Имэйл</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(element, index) in preListOfBulk"
                :key="'preListOfBulk' + index"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ element.firstName }}</td>
                <td>{{ element.lastName }}</td>
                <td>{{ element.phone }}</td>
                <td>{{ element.email }}</td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
export default {
  components: {},
  data: () => ({
    path: null,
    query: null,
    columnNumber: 4,
    firstNameFirst: true,
    preListOfBulk: null,
    bulkText: null,
    newBulkDialog: false,
    selectedSystemRoles: null,
    systemRoles: null,
    filterSelectedDepartment: null,
    selectedDepartment: null,
    departments: null,
    selected: [],
    dialogDelete: false,
    newDialog: false,
    search: "",
    users: null,
    editedIndex: -1,
    editedItem: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      department: null,
    },
    defaultItem: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      department: null,
    },
    rules: {
      required: (value) => !!value || "Шаардлагатай.",
      emailRequired: (value) => !!value || "Gmail шаардлагатай.",
    },
  }),

  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
    items() {
      var list = [];
      if (this.users != null) {
        for (var item of this.users) {
          item.avatar = require("@/assets/img/team-2.jpg");
          list.push(item);
        }
      }
      return list;
    },
    headers2() {
      return this.headerNames;
    },
    users2() {
      var list = [];
      if (this.users != null) {
        var counter = 0;
        for (var item of this.users) {
          counter++;
          item.index = counter;
          item.avatar = require("@/assets/img/team-2.jpg");
          if(item['email']&&item['email'].indexOf('medle.mn') > -1) item.email = null
          list.push(item);
        }
      }
      return list;
    },
  },
  async created() {
    this.headerNames = [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "",
        value: "avatar",
        width: "1%",
        fixed: true,
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "firstName",
        fixed: true,
      },
      {
        text: "Овог",
        align: "start",
        sortable: true,
        value: "lastName",
      },
      {
        text: "ESIS_ID",
        align: "start",
        sortable: true,
        value: "PERSON_ID",
      },
      {
        text: "Утас",
        align: "start",
        sortable: true,
        value: "phone",
      },
      {
        text: "Имэйл (MEDLE.mn)",
        align: "start",
        sortable: true,
        value: "email",
      },
      {
        text: "Имэйл",
        align: "start",
        sortable: true,
        value: "email",
      },
      {
        text: "ЗАН",
        align: "start",
        sortable: true,
        value: "department",
      },
      {
        text: "Идэвx",
        align: "start",
        sortable: true,
        value: "lastLoggedAt",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
    ];

    this._setupp();

    var xx = await fb.db.doc("/sharedlinks/teacheredit").get();
    console.log(xx);
    var sharedDoc = xx.data();
    console.log(sharedDoc);
    this.path = sharedDoc.schoolRef.path + "/teachers"
    if (xx.exists) {
      this.query = sharedDoc.schoolRef
        .collection("teachers")
        .where("role", "==", "teacher")
        .where("deleted", "==", false)
        .orderBy("firstName", "asc");
      this.query.onSnapshot((querySnapshot) => {
        this.users = [];
        querySnapshot.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          if (item.firstName && item.firstName.length > 0) {
            if (item.firstName[0].toUpperCase() != item.firstName[0]) {
              item.firstName =
                item.firstName.charAt(0).toUpperCase() +
                item.firstName.slice(1);
              item.ref.update({ firstName: item.firstName });
            }
          }
          if (item.lastName && item.lastName.length > 0) {
            if (item.lastName[0].toUpperCase() != item.lastName[0]) {
              item.lastName =
                item.lastName.charAt(0).toUpperCase() + item.lastName.slice(1);
              item.ref.update({ lastName: item.lastName });
            }
          }
          this.users.push(item);
        });
      });
    }
  },
  watch: {
    filterSelectedDepartment: function () {
      console.log(this.filterSelectedDepartment);
    },
  },
  methods: {
    _changeFirstNameToLastName() {
      this.users2.forEach((teacher) => {
        teacher.ref
          .update({ firstName: teacher.lastName, lastName: teacher.firstName })
          .then(() => {
            console.log("saving..");
          });
      });
    },
    capitalized(name) {
      const capitalizedFirst = name[0].toUpperCase();
      const rest = name.slice(1);

      return capitalizedFirst + rest;
    },
    _previewSaveBulk() {
      this.preListOfBulk = [];
      if (this.bulkText != null && this.bulkText != "") {
        var arrayOfLines = this.bulkText.match(/[^\r\n]+/g);
        if (arrayOfLines != null && arrayOfLines.length > 0) {
          arrayOfLines.forEach((value) => {
            var lineArray = value.trim().split("\t");
            var newItem = { createdAt: new Date() };

            if (this.columnNumber == 3) {
              if (lineArray[1] && lineArray[1] != null && lineArray[1] != "")
                newItem["phone"] = lineArray[1].trim();

              if (lineArray[2] && lineArray[2] != null && lineArray[2] != "")
                newItem["email"] = lineArray[2].trim().toLowerCase();
              else newItem["email"] = null;

              if (lineArray[0] && lineArray[0] != null && lineArray[0] != "") {
                var fn1 = lineArray[0].trim();
                newItem["firstName"] = this.capitalized(fn1);
                if (newItem.firstName.indexOf(".") > -1) {
                  newItem.firstName = newItem.firstName.substring(
                    newItem.firstName.indexOf(".") + 1,
                    newItem.firstName.length
                  );
                  if (newItem.firstName[0])
                    newItem.lastName = newItem.firstName[0];
                  else newItem.lastName = null;
                }
              }
            } else if (this.columnNumber == 4) {
              var firstNameIndex = 0;
              var lastNameIndex = 0;

              if (this.firstNameFirst == false) {
                firstNameIndex = 1;
                lastNameIndex = 0;
              } else {
                firstNameIndex = 0;
                lastNameIndex = 1;
              }

              if (
                lineArray[firstNameIndex] &&
                lineArray[firstNameIndex] != null &&
                lineArray[firstNameIndex] != ""
              ) {
                var x1 = lineArray[firstNameIndex].trim();
                newItem["firstName"] = this.capitalized(x1);
                if (newItem.firstName.indexOf(".") > -1) {
                  newItem.firstName = newItem.firstName.substring(
                    newItem.firstName.indexOf(".") + 1,
                    newItem.firstName.length
                  );
                }
              }

              if (
                lineArray[lastNameIndex] &&
                lineArray[lastNameIndex] != null &&
                lineArray[lastNameIndex] != ""
              ) {
                var fn2 = lineArray[lastNameIndex].trim();
                newItem["lastName"] = this.capitalized(fn2);
                if (newItem.lastName.indexOf(".") > -1) {
                  newItem.lastName = newItem.lastName.substring(
                    newItem.lastName.indexOf(".") + 1,
                    newItem.lastName.length
                  );
                }
              }
              if (lineArray[2] && lineArray[2] != null && lineArray[2] != "")
                newItem["phone"] = lineArray[2].trim();

              if (lineArray[3] && lineArray[3] != null && lineArray[3] != "")
                newItem["email"] = lineArray[3].trim().toLowerCase();
              else newItem["email"] = null;
            }

            newItem.role = "teacher";
            newItem.roleName = "Багш";
            newItem.accountClosed = false;
            newItem.deleted = false;
            this.preListOfBulk.push(newItem);
          });
        }
      }
    },
    _setupp() {
      fb.db
        .collection("xDuties")
        .where("code", "==", "teacher")
        .orderBy("name", "asc")
        .onSnapshot((querySnapshot) => {
          this.systemRoles = [];
          querySnapshot.forEach((doc) => {
            let xDuty = doc.data();
            xDuty.id = doc.id;
            xDuty.ref = doc.ref;
            xDuty.role = xDuty.code;
            this.systemRoles.push(xDuty);
          });

          if (this.userData && this.userData.school) {
            this.userData.school.ref
              .collection("xDuties")
              .where("code", "==", "teacher")
              .orderBy("name", "asc")
              .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  let xDuty = doc.data();
                  xDuty.id = doc.id;
                  xDuty.ref = doc.ref;
                  xDuty.role = xDuty.code;
                  console.log(xDuty);
                  this.systemRoles.push(xDuty);
                });
              });
          }
        });
      if (this.userData && this.userData.school) {
        this.userData.school.ref
          .collection("xuni-sectors")
          .orderBy("createdAt", "desc")
          .onSnapshot((querySnapshot) => {
            this.departments = [];
            querySnapshot.forEach((doc) => {
              let dep = doc.data();
              dep.id = doc.id;
              dep.ref = doc.ref;
              this.departments.push(dep);
            });
          });
      }
    },
    showAlert(a) {
      if (this.directGo == false) this._detail(a);
    },

    _detail(item) {
      console.log(item);
      this.$router.push({
        name: "UserDetail",
        params: {
          userrefid: item.ref.path,
        },
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _deleteItemConfirm() {
      this.editedItem.ref.update({ deleted: true }).then(() => {
        this.userData.school.ref.update({
          teachersNumber: fb.firestore.FieldValue.increment(-1),
        });
        this.closeDelete();
      });
      // this.editedItem.ref.delete().then(() => {
      //   this.users.splice(this.editedIndex, 1);
      //   this.closeDelete();
      // });
    },
    _deleteItem(item) {
      console.log(item);
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    _editItem(item, index) {
      console.log(item, index);
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);
      if(this.editedItem.email&&this.editedItem.email.includes("medle.mn")) 
        this.editedItem.email = null

      this.selectedSystemRoles = {
        role: this.editedItem.role,
        roleName: this.editedItem.roleName,
        roleRef: this.editedItem.roleRef,
        // id: this.editedItem.roleRef.id,
      };
      this.newDialog = true;
      // for (const dep of this.departments) {
      //   if (
      //     this.editedItem["department-2021"] &&
      //     dep.id == this.editedItem["department-2021"].id
      //   ) {
      //     this.selectedDepartment = dep;
      //     break;
      //   }
      // }
    },
    _close() {
      this.newDialog = false;
      this.messageNotification = null;
      this.selectedDepartment = null;
      this.selectedSystemRoles = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _save() {
      if (
        this.editedItem.firstName &&
        this.editedItem.firstName.trim() !== ""
      ) {
        var x = {};
        x.createdAt = new Date();
        x.firstName = this.editedItem.firstName;
        x.createdAt = new Date();

        if (this.editedItem.lastName) x.lastName = this.editedItem.lastName;
        if (this.editedItem.email)
          x.email = this.editedItem.email.trim().toLowerCase();
        if (this.editedItem.phone) x.phone = this.editedItem.phone;

        if (this.editedIndex === -1) {
          x.role = "teacher";
          x.roleName = "Багш";
          x.accountClosed = false;
          x.deleted = false;
          // Object.assign(this.participants[this.editedIndex], this.editedItem);
          fb.db
            .collection(this.path)
            .doc()
            .set(x)
            .then(() => {
              this._close();
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
          this.userData.school.ref.update({
            teachersNumber: fb.firestore.FieldValue.increment(1),
          });
        } else {
          this.editedItem.ref.update(x).then(() => {
            this._close();
          });
        }
      }
    },
    saveBulk2() {
      var batch = fb.db.batch();
      if (this.preListOfBulk && this.preListOfBulk.length > 0) {
        this.preListOfBulk.forEach((value) => {
          var xx = fb.db.collection(this.path).doc();
          batch.set(xx, value);
        });
        this.userData.school.ref.update({
          teachersNumber: fb.firestore.FieldValue.increment(
            this.preListOfBulk.length
          ),
        });
      }
      batch.commit().then(() => {
        this.bulkText = null;
        this.newBulkDialog = false;
        this.preListOfBulk = null;
      });
    },
    saveBulk() {
      var batch = fb.db.batch();
      if (this.bulkText != null && this.bulkText != "") {
        var arrayOfLines = this.bulkText.match(/[^\r\n]+/g);

        if (arrayOfLines != null && arrayOfLines.length > 0) {
          arrayOfLines.forEach((value) => {
            var lineArray = value.split("\t");
            var newItem = { createdAt: new Date() };

            if (lineArray[0] && lineArray[0] != null && lineArray[0] != "")
              newItem["lastName"] = lineArray[0].trim();
            if (lineArray[1] && lineArray[1] != null && lineArray[1] != "") {
              newItem["firstName"] = lineArray[1].trim();
            }
            if (lineArray[2] && lineArray[2] != null && lineArray[2] != "")
              newItem["phone"] = lineArray[2].trim();
            if (lineArray[3] && lineArray[3] != null && lineArray[3] != "")
              newItem["email"] = lineArray[3].trim().toLowerCase();

            var xx = fb.db.collection(this.path).doc();
            newItem.role = "teacher";
            newItem.roleName = "Багш";
            newItem.accountClosed = false;
            newItem.deleted = false;
            if (newItem.firstName.indexOf(".") > -1) {
              newItem.firstName = newItem.firstName.substring(
                newItem.firstName.indexOf(".") + 1,
                newItem.firstName.length
              );
              console.log(newItem.firstName);
            }
            batch.set(xx, newItem);
          });
        }
        this.userData.school.ref.update({
          teachersNumber: fb.firestore.FieldValue.increment(
            arrayOfLines.length
          ),
        });
      }
      batch.commit().then(() => {
        this.bulkText = null;
        this.newBulkDialog = false;
      });
    },
    getColor(score) {
      if (score > 50) return "green";
      else if (score > 5) return "orange";
      else return "red";
    },
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>
<style>
.preTable {
  border: 1px solid black;
  border-spacing: 0px;
}
.preTable th,
.preTable td {
  border: 1px solid black;
  border-spacing: 0px;
  width: 25%;
  border-collapse: collapse;
  padding: 0px 2px 0px 2px;
}
</style>
